<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- footer -->
        <b-col cols="12">
          <b-form-group
            label="Rodapé"
            label-for="footer"
          >
            <b-form-textarea
              id="footer"
              v-model="companyLocal.configuration.budget.footer"
              rows="4"
              placeholder="..."
            />
          </b-form-group>
        </b-col>
        <!--/ footer -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="save"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { toRefs, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { root }) {
    const { company } = toRefs(props)

    const companyLocal = ref(JSON.parse(JSON.stringify(company.value)))

    const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
      root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    }

    const catchErr = e => {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) this.showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) this.showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else this.showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    }

    const save = async () => {
      try {
        delete companyLocal.value.addresses.created_at
        delete companyLocal.value.addresses.updated_at
        await store.dispatch('company/update', companyLocal.value)
        showToast()
      } catch (e) {
        catchErr(e)
      }
    }

    return {
      companyLocal,
      save,
    }
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
