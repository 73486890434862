<template>
  <b-card>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <!-- media -->
      <b-media no-body>
        <b-media-aside
          class="cursor-pointer"
          @mouseover="mouseOver = true"
          @mouseleave="mouseOver = false"
          @click="$refs.refInputEl.$el.click()"
        >
          <b-form-file
            ref="refInputEl"
            v-model="docData.file"
            accept="image/jpeg, image/png"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />

          <b-overlay
            :show="showOverlay"
            rounded="sm"
            no-fade
          >
            <b-avatar
              rounded="lg"
              variant="light-primary"
              size="125"
            >
              <feather-icon
                v-if="!companyLocal.logo || !companyLocal.logo.url"
                :icon="(mouseOver === false || mouseOver === null) ? 'ImageIcon' : 'UploadIcon'"
                :size="mouseOver === false ? '85' : mouseOver === null ? '85' : '60'"
              />

              <div
                v-if="companyLocal.logo && companyLocal.logo.url"
                class="position-relative d-flex justify-content-center align-items-center"
              >
                <img
                  class="rounded"
                  style="width: 135px; height: 135px;"
                  :src="(companyLocal.logo && companyLocal.logo.url) ? companyLocal.logo.url : companyLocal.logo"
                  alt="Logo"
                >
                <feather-icon
                  v-if="mouseOver === true"
                  icon="UploadIcon"
                  size="60"
                  class="position-absolute"
                />
              </div>
            </b-avatar>
          </b-overlay>

        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            :disabled="!companyLocal.logo"
            @click="handleDeleteLogo"
          >
            Excluir Logo
          </b-button>
          <!--/ reset -->
          <b-card-text>JPG ou PNG. Máx 500kb e dimensão máx. 600px x 600px</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Razão Social"
              rules="required"
              immediate
            >
              <b-form-group
                label="Razão Social"
                label-for="corporate-name"
              >
                <b-form-input
                  v-model="companyLocal.corporate_name"
                  placeholder="Razão Social"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Nome Fantasia"
              label-for="trading-name"
            >
              <b-form-input
                v-model="companyLocal.trading_name"
                placeholder="Name"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="E-mail"
              rules="email"
              immediate
            >
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="companyLocal.email"
                  name="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="CNPJ"
              label-for="identification1"
            >
              <b-form-input
                v-model="companyLocal.identification1"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="CNPJ"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="IE"
              label-for="identification2"
            >
              <b-form-input
                v-model="companyLocal.identification2"
                placeholder="IE"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Telefone"
              label-for="phone"
            >
              <b-form-input
                v-model="companyLocal.phoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
                placeholder="Telefone"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <hr>
          </b-col>

          <!-- ADDRESS -->
          <!-- Field: CEP -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="CEP"
              label-for="zip-code"
            >
              <b-form-input
                id="zip-code"
                v-model="companyLocal.addresses.zip_code"
                v-mask="['#####-###']"
                @keyup="searchZipCode"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Logradouro -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Logradouro"
              label-for="public-place"
            >
              <v-select
                v-model="companyLocal.addresses.public_place"
                :options="publicPlaceOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="public-place"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Endereço -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Endereço"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="companyLocal.addresses.street"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Número -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Número"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="companyLocal.addresses.number"
                type="number"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Bairro -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Bairro"
              label-for="district"
            >
              <b-form-input
                id="district"
                v-model="companyLocal.addresses.neighborhood"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Cidade -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Cidade"
              label-for="address-city"
            >
              <v-select
                v-model="companyLocal.addresses.city_id"
                :reduce="option => option.value"
                :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'"
                :options="cityOptions"
                input-id="address-city"
              >
                <template #no-options="{}">
                  Não encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Estado -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Estado"
              label-for="address-state"
            >
              <v-select
                v-model="companyLocal.addresses.state_id"
                :options="$store.getters['states/getStates']"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="address-state"
                placeholder="Selecione"
                @input="fillCity(companyLocal.addresses.state_id, null, 'cityOptions'), companyLocal.addresses.city_id = null"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Complemento-->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Complemento"
              label-for="complement"
            >
              <b-form-input
                id="complement"
                v-model="companyLocal.addresses.complement"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BFormInvalidFeedback, BFormFile,
  BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BOverlay, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { cpfCnpj } from '@validations'
import fileStoreModule from '@/views/apps/dentists/list/tabs/documents/FileStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormInvalidFeedback,
    BOverlay,
    BAvatar,
    BFormFile,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      cityOptions: [],
    }
  },
  async mounted() {
    await store.dispatch('states/fetchStates')

    // Seleciona estado e preenche cidades
    if (this.companyLocal.addresses.state_id) {
      if (this.companyLocal.addresses.city_id) {
        this.fillCity(this.companyLocal.addresses.state_id, this.companyLocal.addresses.city_id)
      } else {
        this.fillCity(this.companyLocal.addresses.state_id, null)
      }
    }
  },
  methods: {
    async searchZipCode($event) {
      const zipCode = this.companyLocal.addresses.zip_code.trim().replace(/[^0-9]/g, '')

      if (zipCode.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas
        axios.noHeaders = true // enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`)
        if (resp.data.erro) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'O CEP não existe ou não foi encontrado.')
          return
        }

        this.companyLocal.addresses.neighborhood = resp.data.bairro
        this.companyLocal.addresses.complement = resp.data.complemento

        // abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.companyLocal.addresses.public_place = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.companyLocal.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.companyLocal.addresses.street = resp.data.logradouro
        }

        const state = this.$store.getters['states/getStates'].find(o => o.initials === resp.data.uf)
        this.companyLocal.addresses.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade, 'cityOptions', 'tenant')
      }
    },

    async fillCity(stateId, city = null, prop = 'cityOptions') {
      try {
        this[prop] = []
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          resp.data.map(c => this[prop].push({ value: c.id, label: c.city }))

          if (city) {
            // procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            this.companyLocal.addresses.city_id = ct.value
          }
        }
      } catch (error) {
        this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
      }
    },

  },
  setup(props, { root }) {
    localize('pt_BR') // vee-validate messages

    const DOCUMENT_STORE_MODULE = 'store-file'

    // Register module
    if (!store.hasModule(DOCUMENT_STORE_MODULE)) store.registerModule(DOCUMENT_STORE_MODULE, fileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_STORE_MODULE)) store.unregisterModule(DOCUMENT_STORE_MODULE)
    })

    const { company } = toRefs(props)

    const companyLocal = ref(JSON.parse(JSON.stringify(company.value)))
    const showOverlay = ref(false)
    const mouseOver = ref(null)
    const refInputEl = ref(null)

    const publicPlaceOptions = [
      { label: 'Rua', value: 'Rua' },
      { label: 'Avenida', value: 'Avenida' },
      { label: 'Rodovia', value: 'Rodovia' },
      { label: 'Estrada', value: 'Estrada' },
      { label: 'Lote', value: 'Lote' },
      { label: 'Servidão', value: 'Servidão' },
      { label: 'Quadra', value: 'Quadra' },
      { label: 'Travessa', value: 'Travessa' },
      { label: 'Linha', value: 'Linha' },
    ]

    const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
      root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    }

    const catchErr = e => {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    }

    const save = async () => {
      try {
        delete companyLocal.value.addresses.created_at
        delete companyLocal.value.addresses.updated_at
        await store.dispatch('company/update', companyLocal.value)
        showToast()
      } catch (e) {
        catchErr(e)
      }
    }

    const blankDocData = {
      id: null,
      name: 'Logo',
      type: 'company',
      category: 'logo',
      path: 'companyLogo',
      file: null,
    }
    const docData = ref(JSON.parse(JSON.stringify(blankDocData)))

    const inputImageRenderer = async () => {
      try {
        let resp = null
        showOverlay.value = true
        docData.value.id = companyLocal.value.logo?.id || null
        if (docData.value.id) resp = await store.dispatch('store-file/update', docData.value)
        else resp = await store.dispatch('store-file/add', docData.value)

        companyLocal.value = resp.data
        store.commit('company/SET_COMPANY', companyLocal.value)

        showOverlay.value = false
        showToast()
      } catch (e) {
        showOverlay.value = false
        catchErr(e)
      }
    }

    const deleteLogo = async () => {
      try {
        showOverlay.value = true
        await store.dispatch('store-file/delete', companyLocal.value.logo)
        delete companyLocal.value.logo
        store.commit('company/DELETE_LOGO_COMPANY')
        showOverlay.value = false
      } catch (e) {
        showOverlay.value = false
        catchErr(e)
      }
    }

    const handleDeleteLogo = () => {
      root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja excluir a logo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await deleteLogo()
          root.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'O registro foi reativado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }

    return {
      showOverlay,
      mouseOver,
      publicPlaceOptions,
      companyLocal,
      cpfCnpj,
      refInputEl,
      docData,

      save,
      inputImageRenderer,
      handleDeleteLogo,
    }
  },
}
</script>
