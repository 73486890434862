<template>
  <div>
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="6">
            <b-card
              class="popular"
              align="center"
            >
              <h2>Alterar Assinatura</h2>
              <validation-observer
                #default="{ invalid }"
                ref="refFormObserver"
              >
                <!-- img -->
                <b-img
                  v-if="pricing.standardPlan.img"
                  :src="pricing.standardPlan.img"
                  class="mb-1"
                  alt="svg img"
                />
                <!--/ img -->
                <h3>Dental SIGO</h3>
                <b-card-text>Plano mensal do tamanho da sua clínica.</b-card-text>
                <b-card-text>Escolha o número desejado de usuários se quiser alterar seu plano.</b-card-text>

                <label
                  class="d-flex justify-content-start mb-1 mb-md-0"
                  style="font-size: 0.93rem;"
                >Número de usuários</label>

                <validation-provider
                  #default="{ errors }"
                  name="Quantidade"
                  rules="required|min_value:2"
                  immediate
                >
                  <b-form-group>
                    <b-form-input
                      v-model="quantity"
                      type="number"
                      min="1"
                      class="form-control"
                      size="lg"
                      :state="errors.length > 0 ? false : null"
                      @change="calcTotalPrice"
                    />
                  </b-form-group>
                </validation-provider>

                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">R$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ calcTotalPrice() }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mensais</sub>
                  </div>
                </div>

                <b-list-group class="list-group-circle text-center d-flex">

                  <b-list-group-item>
                    <span class="mr-1">
                      <feather-icon
                        icon="CheckIcon"
                        size="16"
                      />
                    </span>

                    {{ `${quantity} Usuário${quantity > 1 ? 's' : ''}`, }}
                  </b-list-group-item>

                  <b-list-group-item>
                    <span class="mr-1">
                      <feather-icon
                        icon="CheckIcon"
                        size="16"
                      />
                    </span>

                    {{ `${quantity} Agenda${quantity > 1 ? 's' : ''}` }}
                  </b-list-group-item>

                </b-list-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  :disabled="paymentProcessing || invalid || subscriptionInfo.usersQuantity === parseInt(quantity, 10)"
                  @click="handleChangeSubscription"
                >
                  R$ {{ calcTotalPrice() }} Alterar plano.
                </b-button>

              </validation-observer>

            </b-card>
          </b-col>

          <b-col md="6">
            <b-card
              align="center"
            >
              <h2>Novo meio de pagamento</h2>
              <form
                id="payment-form"
                class="border"
              >
                <div id="link-authentication-element">
                  <!--Stripe.js injects the Link Authentication Element-->
                </div>
                <div id="payment-el">
                  <!--Stripe.js injects the Payment Element-->
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  :disabled="paymentProcessing"
                  @click="processPayment"
                >
                  {{ paymentProcessing ? "Processando..." : "Adicionar meio de pagamento." }}
                </b-button>

              </form>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr>

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Search -->
        <b-col
          cols="12"
          md="12"
        >
          <h2>MEIOS DE PAGAMENTO</h2>
        </b-col>

      </b-row>

    </div>

    <b-table
      ref="refTable"
      class="position-relative mt-2 tableMinHeght"
      :items="subscriptionInfo.paymentMethods"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="Nenhum resultado encontrado"
      no-sort-reset
      :busy="showLoading || isBusy"
    >
      <template #table-busy>
        <div class="text-center text-warning my-2">
          <b-spinner
            class="align-middle"
          />
          <strong> Aguardando dados...</strong>
        </div>
      </template>
      <template #cell(cardExpiration)="data">
        {{ data.item.card.exp_month }}/{{ data.item.card.exp_year }}
      </template>

      <template #cell(cardDefault)="data">
        <feather-icon
          v-if="data.item.id === subscriptionInfo.defaultPaymentMethod.id"
          icon="CheckCircleIcon"
          size="20"
          class="text-success stroke-current"
        />
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          v-if="data.item.id !== subscriptionInfo.defaultPaymentMethod.id"
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            @click="handleMakeDefault(data.item)"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Tornar Padrão</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="handleDelete(data.item)"
          >
            <feather-icon icon="TrashIcon" />
            <span
              class="align-middle ml-50"
            >Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <hr>

    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-button
          v-if="subscriptionInfo.subscriptionStatus === 'ACTIVE'"
          variant="danger"
          @click="handleCancelSubscription"
        >
          Cancelar Inscrição e remover conta.
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from '@axios'
import { loadStripe } from '@stripe/stripe-js'
import {
  BButton, BTable, BDropdown, BDropdownItem, BRow, BCol, BFormGroup,
  BSpinner, BCard, BCardText, BImg, BListGroup, BListGroupItem, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

export default {
  name: 'StripePayment',
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BCard,
    BFormGroup,
    BCardText,
    BImg,
    BListGroup,
    BFormInput,
    BListGroupItem,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    clientSecret: {
      type: String,
      default: '',
    },

    subscriptionInfo: {
      type: Object,
      default: () => {},
    },

    showLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stripe: {},
      paymentElement: {},
      elements: {},
      paymentProcessing: false,
      optionsToast: { timeout: 6000, position: 'topCenter' },
      isBusy: false,
      quantity: this.subscriptionInfo.usersQuantity || 2,
      monthlyPrice: 99.99,
      pricing: {
        standardPlan: {
          // eslint-disable-next-line global-require
          img: require('@/assets/images/logo/logo.png'),
        },
      },

      plusUserValue: 19.99,
      // eslint-disable-next-line global-require
      img: require('@/assets/images/logo/logo.png'),
      tableColumns: [
        {
          key: 'card.brand',
          label: 'Bandeira',
          tdClass: 'cardBrandTdClass',
          sortable: false,
        },
        {
          key: 'card.last4',
          label: 'Final',
          tdClass: 'cardFinalTdClass',
          sortable: false,
        },
        {
          key: 'cardExpiration',
          label: 'Válido até',
          tdClass: 'finalTdClass',
          sortable: false,
        },
        {
          key: 'cardDefault',
          label: 'Padrão',
          tdClass: 'defaultTdClass',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'ações',
          tdClass: 'actionsTdClass',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    skin() {
      return useAppConfig().skin.value
    },
  },

  watch: {
    skin() {
      this.initiateStripeElements({
        theme: this.skin === 'light' ? 'stripe' : 'night',
      })
    },
  },

  async mounted() {
    localize('pt_BR') // vee-validate messages
    this.initiateStripeElements({
      theme: this.skin === 'light' ? 'stripe' : 'night',
    })
  },

  methods: {
    async initiateStripeElements(appearance) {
      this.stripe = await loadStripe(`${process.env.VUE_APP_STRIPE_KEY}`, {
        locale: 'pt-br',
      })

      const payload = {
        clientSecret: this.clientSecret,
        appearance,
      }

      this.elements = this.stripe.elements(payload)

      const paymentElementOptions = {
        layout: 'tabs',
      }

      this.paymentElement = this.elements.create(
        'payment',
        paymentElementOptions,
      )

      this.paymentElement.mount('#payment-el')
    },

    showToast(icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') {
      this.$root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    },

    handleError(e) {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) this.showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) this.showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else this.showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    },

    calcTotalPrice() {
      if (this.quantity <= 2) return this.monthlyPrice.toFixed(2)
      return (
        this.monthlyPrice + (this.quantity - 2) * this.plusUserValue
      ).toFixed(2)
    },

    async changeSubscription() {
      // eslint-disable-next-line no-useless-catch
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/updateSubscription`, {
          plan: 'ESSENTIAL',
          quantity: this.quantity,
        })
      } catch (e) {
        this.$emit('refresh')
        throw e
      }
    },

    handleChangeSubscription() {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja alterar a quantidade de usuários no seu plano?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            await this.changeSubscription()
            this.$root.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'O plano foi alterado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } catch (e) {
            this.handleError(e)
          }
        }
      })
    },

    async processPayment() {
      this.paymentProcessing = true

      const { setupIntent, error } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      })

      if (error) {
        this.paymentProcessing = false
        this.$toast.warning(
          'Por favor revise o formulário',
          'Aviso',
          this.optionsToast,
        )

        if (error.type === 'card_error' || error.type === 'validation_error') {
          // alert(error.message);
        } else {
          // alert("An unexpected error occurred.");
        }

        return
      }

      try {
        this.isBusy = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/add-payment-method`, {
          paymentMethod: setupIntent.payment_method,
        })
        this.paymentProcessing = false
        this.$root.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Novo meio de pagamento adicionado com sucesso.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.$emit('refresh')
        this.isBusy = false
      } catch (e) {
        this.handleError(e)
        this.isBusy = false
      }
    },

    async makePaymentMethodDefault(data) {
      try {
        this.isBusy = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/update-default-payment-method`, {
          paymentMethod: data.id,
        })
        this.$emit('refresh')
        this.isBusy = false
      } catch (e) {
        this.handleError(e)
        this.isBusy = false
      }
    },

    handleMakeDefault(data) {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja tornar o registro padrão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.makePaymentMethodDefault(data)
          this.showToast('success', 'Sucesso', 'O registro foi alterado.')
        }
      })
    },

    async deletePaymentMethod(data) {
      try {
        this.isBusy = true
        await axios.delete(`${process.env.VUE_APP_API_URL}/stripe/delete-payment-method/${data.id}`)
        this.$emit('refresh')
        this.isBusy = false
      } catch (e) {
        this.handleError(e)
        this.isBusy = false
      }
    },

    handleDelete(data) {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja excluir o registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.deletePaymentMethod(data)
          this.showToast('success', 'Sucesso', 'O registro foi excluído.')
        }
      })
    },

    async cancelSubscription() {
      try {
        this.isBusy = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/cancel/subscription`, {
          plan: 'ESSENTIAL',
        })
        this.$emit('refresh')
        this.isBusy = false
      } catch (e) {
        this.handleError(e)
        this.isBusy = false
      }
    },

    handleCancelSubscription() {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja cancelar a assinatura e excluir a sua conta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.cancelSubscription()
          this.showToast('success', 'Sucesso', 'Assinatura cancelada.')
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

form {
  min-height: 409px;
  align-self: center;
  padding: 40px;
}

.border {
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.Label {
  color: white !important;
}

/* Buttons and links */
button {
  font-family: Arial, sans-serif;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
.form-group label {
  font-weight: normal;
}

.form-group.has-error label {
  color: #df1b41;
  font-weight: normal;
}

.list-group-item {
    padding: 0.4rem 1.25rem !important;
}
</style>
