<template>
  <b-overlay
    :show="showLoadingLocal"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >

    <div class="text-center">
      <h1
        class="mt-2"
      >
        Olá,
        {{ clientSecret && subscriptionInfo.subscriptionStatus === 'ON_GENERIC_TRIAL' && subscriptionInfo.trialEndInDays > 0 ? `você ainda tem ${subscriptionInfo.trialEndInDays} dias de teste gratúito.` : '' }}
        {{ clientSecret && subscriptionInfo.subscriptionStatus === 'ON_GENERIC_TRIAL' && subscriptionInfo.trialEndInDays === 0 ? `hoje é seu último dia de teste gratúito.` : '' }}
        {{ clientSecret && subscriptionInfo.subscriptionStatus === 'ACTIVE' ? `seu plano atual tem  ${subscriptionInfo.usersQuantity} usuários.` : '' }}
        {{ clientSecret && subscriptionInfo.subscriptionStatus === 'CANCELED_ON_GRACE_PERIOD' ? `sua assinatura foi cancelada, mas ainda há tempoi de reativá-la.` : '' }}
      </h1>
      <p class="mb-2 mt-1 pb-75">
        {{ headMessage }} <br>
        {{ headMessage2 }}
      </p>
    </div>

    <subscribe
      v-if="clientSecret && subscriptionInfo.subscriptionStatus === 'ON_GENERIC_TRIAL'"
      :client-secret="clientSecret"
      action="subscribe"
      @refresh="getSubscriptionInfo"
    />

    <handle
      v-if="clientSecret && (subscriptionInfo.subscriptionStatus === 'ACTIVE')"
      ref="handleref"
      :client-secret="clientSecret"
      :subscription-info="subscriptionInfo"
      :show-loading="showLoading"
      @refresh="getSubscriptionInfo"
    />

    <b-button
      v-if="subscriptionInfo.subscriptionStatus === 'CANCELED_ON_GRACE_PERIOD'"
      variant="primary"
      @click="resumeSubscription"
    >
      Reativar minha assinatura.
    </b-button>

    <p class="mb-2 mt-1 pb-75 text-center">
      Se você está tendo alguma dificuldade ou se tem dúvidas utilize o formulário ao fim desta página para entrar em contato conosco. Teremos prazer em lhe ajudar.
    </p>

    <h3>Entre em contato</h3>
    <b-form-textarea
      id="message"
      v-model="contactMessage"
      rows="8"
      trim
    />
    <b-button
      class="mt-2"
      variant="primary"
      :disabled="!contactMessage"
      @click="sendMessage"
    >
      Enviar mensagem
    </b-button>

  </b-overlay>
</template>

<script>
import axios from '@axios'
import { ref } from '@vue/composition-api'
import {
  BButton, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import Subscribe from './Subscribe.vue'
import Handle from './Handle.vue'

export default {
  components: {
    BButton,
    BOverlay,
    BFormTextarea,

    Subscribe,
    Handle,
  },

  setup(props, { root }) {
    const clientSecret = ref('')
    const subscriptionInfo = ref({})
    const headMessage = ref('')
    const headMessage2 = ref('')
    const handleref = ref(null)
    const showLoading = ref(false)
    const showLoadingLocal = ref(false)
    const contactMessage = ref('')

    const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
      root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    }

    const handleError = e => {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    }

    const getClientSecret = async () => {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/stripe/intent`)
        clientSecret.value = resp.data.intent.client_secret
      } catch (e) {
        handleError(e)
      }
    }

    const getSubscriptionInfo = async () => {
      try {
        showLoading.value = true
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/stripe/get-subscription-info`)
        subscriptionInfo.value = resp.data
        if (handleref.value) handleref.value.$refs.refTable.refresh()
        showLoading.value = false
        if (subscriptionInfo.value.subscriptionStatus === 'ACTIVE') {
          headMessage.value = 'Para alterar seu plano, defina o número de usuários desejado abaixo e clique no botão Alterar Plano.'
          headMessage2.value = 'Se deseja adicionar meios de pagamento, use o formulário do quadro da direia logo abaixo.'
        }
        if (subscriptionInfo.value.subscriptionStatus === 'CANCELED_ON_GRACE_PERIOD') {
          headMessage.value = `Você ainda tem ${subscriptionInfo.value.trialEndInDays} dias para reativar sua conta.`
          headMessage2.value = 'Reative sua assinatura agora mesmo sem prejuízo algum.'
        }
      } catch (e) {
        if (e.response.status === 412 && e.response.data.subscriptionStatus === 'ON_GENERIC_TRIAL') {
          subscriptionInfo.value.subscriptionStatus = 'ON_GENERIC_TRIAL'
          subscriptionInfo.value.trialEndInDays = e.response.data.trialEndInDays
          headMessage.value = 'Você ainda está no período gratúito de testes, aproveite para assinar o Dental SIGO antes que o teste acabe.'
          headMessage2.value = 'Utilize o formulário abaixo para assinar um plano conforme sua necessiadade.'
          showLoading.value = false
        } else {
          handleError(e)
        }
      }
    }

    getClientSecret()
    getSubscriptionInfo()

    const resumeSubscription = async () => {
      try {
        showLoadingLocal.value = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/resume/subscription`, {
          plan: 'ESSENTIAL',
        })
        root.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: 'Assinatura reativada',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        getSubscriptionInfo()
        showLoadingLocal.value = false
      } catch (e) {
        handleError(e)
        showLoadingLocal.value = false
      }
    }

    const sendMessage = async () => {
      try {
        showLoadingLocal.value = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/send-feedback`, {
          message: contactMessage.value,
        })
        root.$swal({
          icon: 'success',
          title: 'Sucesso',
          text: 'Já recebemos a sua mensagem. Retornaremos assim que for possível.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        showLoadingLocal.value = false
        contactMessage.value = ''
      } catch (e) {
        handleError(e)
        showLoadingLocal.value = false
      }
    }

    return {
      clientSecret,
      subscriptionInfo,
      headMessage,
      headMessage2,
      handleref,
      showLoading,
      showLoadingLocal,
      contactMessage,

      getSubscriptionInfo,
      resumeSubscription,
      sendMessage,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/pages/page-pricing.scss';

  .pricing-free-trial {
    margin-top: 2.5rem;
    margin-bottom: 3.3rem;
  }
</style>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/variables';
  @import '~@core/scss/base/components/variables-dark';

  .dark-layout {
    div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
    }
  }
</style>
