<template>
  <div>
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="6">
            <b-card
              align="center"
            >
              <validation-observer
                #default="{ invalid }"
                ref="refFormObserver"
              >
                <form
                  id="payment-form"
                  class="border"
                >
                  <label
                    class="d-flex justify-content-start mb-1 mb-md-0"
                    style="font-size: 0.93rem;"
                  >Número de usuários</label>

                  <validation-provider
                    #default="{ errors }"
                    name="Quantidade"
                    rules="required|min_value:2"
                    immediate
                  >

                    <b-form-group>
                      <b-form-input
                        v-model="quantity"
                        type="number"
                        min="1"
                        class="form-control"
                        size="lg"
                        :state="errors.length > 0 ? false : null"
                        @change="calcTotalPrice"
                      />
                    </b-form-group>
                  </validation-provider>
                  <input
                    type="hidden"
                    name="plan"
                    value="ESSENTIAL"
                  >
                  <div id="link-authentication-element">
                    <!--Stripe.js injects the Link Authentication Element-->
                  </div>
                  <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                    :disabled="paymentProcessing || invalid"
                    @click="processPayment"
                  >
                    R$ {{ calcTotalPrice() }}
                    {{ paymentProcessing ? "Processando..." : "Assine agora." }}
                  </b-button>
                </form>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              class="popular"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-center"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>
              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">R$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ calcTotalPrice() }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mensais</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} / year</small>
              </div>
              <!--/ annual plan -->
              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-center d-flex">

                <b-list-group-item>
                  <span class="mr-1">
                    <feather-icon
                      icon="CheckIcon"
                      size="16"
                    />
                  </span>

                  {{ `${quantity} Usuário${quantity > 1 ? 's' : ''}`, }}
                </b-list-group-item>

                <b-list-group-item>
                  <span class="mr-1">
                    <feather-icon
                      icon="CheckIcon"
                      size="16"
                    />
                  </span>

                  {{ `${quantity} Agenda${quantity > 1 ? 's' : ''}` }}
                </b-list-group-item>

                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  <span class="mr-1">
                    <feather-icon
                      icon="CheckIcon"
                      size="16"
                    />
                  </span>

                  {{ data }}
                </b-list-group-item>

              </b-list-group>

            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import axios from '@axios'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,
  BRow, BImg, BCol, BCard, BBadge, BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  name: 'StripeSubscribe',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BImg,
    BCol,
    BCard,
    BCardText,
    BBadge,
    BListGroup,
    BListGroupItem,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    clientSecret: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      monthlyPlanShow: true,
      pricing: {
        standardPlan: {
          title: 'Dental SIGO',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/logo/logo.png'),
          subtitle: 'Plano mensal do tamanho da sua clínica.',
          monthlyPrice: 99.99,
          yearlyPlan: {
            perMonth: 40,
            totalAnual: 480,
          },
          planBenefits: [],
          popular: false,
        },
        qandA: [
          {
            question: 'Does my subscription automatically renew?',
            ans:
              'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question: 'Can I store the item on an intranet so everyone has access?',
            ans:
              'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Am I allowed to modify the item that I purchased?',
            ans:
              'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },
      minQuantity: 2,
      plusUserValue: 19.99,
      stripe: {},
      client_secret: '',
      paymentElement: {},
      elements: {},
      paymentProcessing: false,
      quantity: 2,
      totalPrice: 99.99,
      plan: 'ESSENTIAL',
      optionsToast: { timeout: 6000, position: 'topCenter' },
    }
  },

  computed: {
    skin() {
      return useAppConfig().skin.value
    },
  },

  watch: {
    skin() {
      this.initiateStripeElements({
        theme: this.skin === 'light' ? 'stripe' : 'night',
      })
    },
  },

  mounted() {
    localize('pt_BR') // vee-validate messages

    this.initiateStripeElements({
      theme: this.skin === 'light' ? 'stripe' : 'night',
    })

    this.pricing.standardPlan.planBenefits = [
      'Confirmação de agendamento por Whatsapp',
      'Mensagens automática por e-mail',
      'Prontuário digital completo',
      'Número infinito de pacientes',
      'Controle financeiro',
      'Odontograma',
      'Chat interno',
      'Suporte por email e chat',
      'e muito mais ...',
    ]
  },

  methods: {
    async initiateStripeElements(appearance) {
      this.stripe = await loadStripe(`${process.env.VUE_APP_STRIPE_KEY}`, {
        locale: 'pt-br',
      })

      const payload = {
        clientSecret: this.clientSecret,
        appearance,
      }

      this.elements = this.stripe.elements(payload)

      const paymentElementOptions = {
        layout: 'tabs',
      }

      this.paymentElement = this.elements.create(
        'payment',
        paymentElementOptions,
      )

      this.paymentElement.mount('#payment-element')
    },

    showToast(icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') {
      this.$root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    },

    handleError(e) {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) this.showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) this.showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else this.showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    },

    async processPayment() {
      this.paymentProcessing = true

      const { setupIntent, error } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      })

      if (error || this.quantity < 2) {
        this.paymentProcessing = false
        this.$toast.warning(
          'Por favor revise o formulário',
          'Aviso',
          this.optionsToast,
        )

        if (error.type === 'card_error' || error.type === 'validation_error') {
          // alert(error.message);
        } else {
          // alert("An unexpected error occurred.");
        }

        return
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/${this.action}`, {
          plan: this.plan,
          quantity: this.quantity,
          paymentMethod: setupIntent.payment_method,
        })
        this.paymentProcessing = false
        this.showToast('success', 'Sucesso', 'Recebemos seu pagamento e seu plano foi ativado. Obrigado')
        this.$emit('refresh')
      } catch (e) {
        this.handleError(e)
      }
    },

    calcTotalPrice() {
      if (this.quantity <= 2) return this.pricing.standardPlan.monthlyPrice.toFixed(2)
      return (
        this.pricing.standardPlan.monthlyPrice
        + (this.quantity - 2) * this.plusUserValue
      ).toFixed(2)
    },
  },
}
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

form {
  min-height: 409px;
  align-self: center;
  padding: 40px;
}

.border {
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.Label {
  color: white !important;
}

/* Buttons and links */
button {
  font-family: Arial, sans-serif;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
.form-group label {
  font-weight: normal;
}

.form-group.has-error label {
  color: #df1b41;
  font-weight: normal;
}

.list-group-item {
    padding: 0.4rem 1.25rem !important;
}
</style>
