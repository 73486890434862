<template>
  <b-card>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="MailIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Configuração de conta de e-mail
            </h4>
          </div>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Host"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="mail-host"
              label="Host (smtp.dominio.com.br)"
            >
              <b-form-input
                id="mail-host"
                v-model="localconfigMail.mail_host"
                placeholder="smtp.dominio.com.br"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Porta"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="mail-port"
              label="Porta (465/587)"
            >
              <b-form-input
                id="mail-port"
                v-model="localconfigMail.mail_port"
                placeholder="587"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ facebook -->

        <!-- google+ -->
        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Email de envio"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="account-mail"
              label="Email de envio (seu@email.com.br)"
            >
              <b-form-input
                id="account-mail"
                v-model="localconfigMail.mail_from_address"
                placeholder="seu@email.com.br"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Nome"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="name"
              label="Nome (nome para envio)"
            >
              <b-form-input
                id="name"
                v-model="localconfigMail.mail_from_name"
                placeholder="Nome do remetente"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Criptografia"
            rules="required"
            immediate
          >
            <b-form-group
              label="Criptografia (ssl/tls)"
              label-for="ssl-tls"
            >
              <b-form-input
                id="ssl-tls"
                v-model="localconfigMail.mail_encryption"
                placeholder="tls"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Username"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="username"
              label="Username (seu@email.com.br)"
            >
              <b-form-input
                id="username"
                v-model="localconfigMail.mail_username"
                placeholder="seu@email.com.br"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--/ quora -->

        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Password"
            rules="required"
            immediate
          >
            <b-form-group
              label-for="password"
              label="Password (sua senha)"
            >
              <b-form-input
                id="password"
                v-model="localconfigMail.mail_password"
                placeholder="Sua senha de email"
                type="password"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-button
            variant="primary"
            class="mt-2"
            block
            :disabled="invalid"
            @click="save"
          >
            Salvar
          </b-button>

        </b-col>
      </b-row>
    </validation-observer>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-row>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <div class="d-flex align-items-center mb-3">
            <feather-icon
              icon="UserIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Teste de configuração de email
            </h4>
          </div>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{errors}"
            name="Enviar teste para"
            rules="required|email"
            immediate
          >
            <b-form-group
              label-for="send-to"
              label="Enviar teste para"
            >
              <b-form-input
                id="send-to"
                v-model="testMail.email"
                placeholder="email@teste.com.br"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="subject"
            label="Assunto"
          >
            <b-form-input
              id="subject"
              v-model="testMail.subject"
              placeholder="Assunto"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-for="message"
            label="Mensagem"
          >
            <b-form-input
              id="message"
              v-model="testMail.message"
              placeholder="Mensagem de teste"
            />
          </b-form-group>
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            variant="primary"
            class="mt-1"
            block
            :disabled="invalid"
            @click="sendMailTest"
          >
            Enviar email teste
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BRow, BCol, BCard, BFormInvalidFeedback,
} from 'bootstrap-vue'
import axios from '@axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },

  setup(props, { root }) {
    localize('pt_BR') // vee-validate messages

    const testMail = ref({
      email: '',
      subject: 'Teste de configuração de email',
      message: 'Este é apenas um teste de configuração de email.',
    })

    const blankData = {
      mail_host: '',
      mail_port: '',
      mail_from_address: '',
      mail_from_name: '',
      mail_encryption: '',
      mail_username: '',
      mail_password: '',
    }
    const localconfigMail = ref(JSON.parse(JSON.stringify(blankData)))

    const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
      root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    }

    const catchErr = e => {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    }

    const fetch = async () => {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/email/config`)
        if (resp.data.id) localconfigMail.value = resp.data
      } catch (e) {
        catchErr(e)
      }
    }
    fetch()

    const sendMailTest = async () => {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/email/config/send-mail-test`, testMail.value)
        showToast(
          'success',
          'Sucesso',
          'O e-mail foi enviado sem erros do nosso lado, no entanto não podemos garantir que chegou ao seu destino. Confira por favor.',
        )
      } catch (e) {
        catchErr(e)
      }
    }

    const save = async () => {
      try {
        await axios.put(`${process.env.VUE_APP_API_URL}/email/config/update`, localconfigMail.value)
        showToast()
      } catch (e) {
        catchErr(e)
      }
    }

    return {
      testMail,
      localconfigMail,
      required,

      sendMailTest,
      save,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
