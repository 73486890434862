var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('feather-icon',{attrs:{"icon":"MailIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" Configuração de conta de e-mail ")])],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Host","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"mail-host","label":"Host (smtp.dominio.com.br)"}},[_c('b-form-input',{attrs:{"id":"mail-host","placeholder":"smtp.dominio.com.br","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_host),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_host", $$v)},expression:"localconfigMail.mail_host"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Porta","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"mail-port","label":"Porta (465/587)"}},[_c('b-form-input',{attrs:{"id":"mail-port","placeholder":"587","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_port),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_port", $$v)},expression:"localconfigMail.mail_port"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Email de envio","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account-mail","label":"Email de envio (seu@email.com.br)"}},[_c('b-form-input',{attrs:{"id":"account-mail","placeholder":"seu@email.com.br","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_from_address),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_from_address", $$v)},expression:"localconfigMail.mail_from_address"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name","label":"Nome (nome para envio)"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Nome do remetente","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_from_name),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_from_name", $$v)},expression:"localconfigMail.mail_from_name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Criptografia","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Criptografia (ssl/tls)","label-for":"ssl-tls"}},[_c('b-form-input',{attrs:{"id":"ssl-tls","placeholder":"tls","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_encryption),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_encryption", $$v)},expression:"localconfigMail.mail_encryption"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"username","label":"Username (seu@email.com.br)"}},[_c('b-form-input',{attrs:{"id":"username","placeholder":"seu@email.com.br","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_username),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_username", $$v)},expression:"localconfigMail.mail_username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"password","label":"Password (sua senha)"}},[_c('b-form-input',{attrs:{"id":"password","placeholder":"Sua senha de email","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.localconfigMail.mail_password),callback:function ($$v) {_vm.$set(_vm.localconfigMail, "mail_password", $$v)},expression:"localconfigMail.mail_password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":"","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)]}}])}),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"my-2"})]),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" Teste de configuração de email ")])],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Enviar teste para","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"send-to","label":"Enviar teste para"}},[_c('b-form-input',{attrs:{"id":"send-to","placeholder":"email@teste.com.br","state":errors.length > 0 ? false : null},model:{value:(_vm.testMail.email),callback:function ($$v) {_vm.$set(_vm.testMail, "email", $$v)},expression:"testMail.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"subject","label":"Assunto"}},[_c('b-form-input',{attrs:{"id":"subject","placeholder":"Assunto"},model:{value:(_vm.testMail.subject),callback:function ($$v) {_vm.$set(_vm.testMail, "subject", $$v)},expression:"testMail.subject"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"message","label":"Mensagem"}},[_c('b-form-input',{attrs:{"id":"message","placeholder":"Mensagem de teste"},model:{value:(_vm.testMail.message),callback:function ($$v) {_vm.$set(_vm.testMail, "message", $$v)},expression:"testMail.message"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","block":"","disabled":invalid},on:{"click":_vm.sendMailTest}},[_vm._v(" Enviar email teste ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }