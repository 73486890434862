<template>
  <b-tabs
    v-if="company.id"
    small
  >
    <!-- Tab: Dados da Clínica -->
    <b-tab
      :active.sync="activeTabCompanyData"
      @click="refreshData('activeTabCompanyData')"
    >
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Dados da Clínica</span>
      </template>
      <b-card>
        <company-data
          ref="companyDataRef"
          :company="company"
        />
      </b-card>
    </b-tab>

    <!-- Tab: Dados Gerais -->
    <b-tab
      :active.sync="activeTabSetting"
      @click="refreshData('activeTabSetting')"
    >
      <template #title>
        <feather-icon
          icon="GridIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Dados Gerais</span>
      </template>
      <setting
        ref="settingRef"
        :company="company"
      />
    </b-tab>

    <!-- Tab: Comunicação -->
    <b-tab
      :active.sync="activeTabComunication"
      @click="refreshData('activeTabComunication')"
    >
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Comunicação</span>
      </template>
      <b-card>
        AQUI SERÃO CONFIGURADOS OS MODELOS DE MENSAGENS AUTOMÁTICAS DE EMAIL (ANIVERSÁRIOS, AVISO DE AGENDAMENTO, INADIMPLÊNCIA ETC...)
        <!-- <user-edit-tab-finances
          :user-data="userData"
          @refetchData="refetchData"
        /> -->
      </b-card>
    </b-tab>

    <!-- Tab: Segurança -->
    <b-tab
      :active.sync="activeTabSecurity"
      @click="refreshData('activeTabSecurity')"
    >
      <template #title>
        <feather-icon
          icon="FolderPlusIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Segurança</span>
      </template>
      <b-card>
        CONFIGURAÇÃO DE DADOS DE SEGURANÇA COMO SENHA DE ADMINISTRADOR PARA EFETUAR DETERMINADAS AÇÕES
        <!-- <user-edit-tab-treatments
          :user-data="userData"
        /> -->
      </b-card>
    </b-tab>

    <!-- Tab: subscription Dental Sligo -->
    <b-tab
      :active.sync="activeTabSubscription"
      @click="refreshData('activeTabSubscription')"
    >
      <template #title>
        <feather-icon
          icon="MoreHorizontalIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Plano DENTAL SIGO</span>
      </template>
      <stripe />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import store from '@/store/index'
import Stripe from './stripe/Main.vue'
import CompanyData from './tabs/CompanyData.vue'
import Setting from './Setting.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    CompanyData,
    Setting,
    Stripe,
  },

  setup() {
    store.dispatch('company/fetch')
    const company = computed(() => store.getters['company/getCompany'])
    const activeTabCompanyData = ref(true)
    const activeTabSetting = ref(false)
    const activeTabComunication = ref(false)
    const activeTabSecurity = ref(false)
    const activeTabSubscription = ref(false)
    const companyDataRef = ref(null)
    const settingRef = ref(null)

    const refresh = () => {
      companyDataRef.value.companyLocal = JSON.parse(JSON.stringify(company.value))
      settingRef.value.settingBudgetRef.companyLocal = JSON.parse(JSON.stringify(company.value))
    }

    const refreshData = data => {
      if (data === 'activeTabCompanyData' && !activeTabCompanyData.value) refresh()
      else if (data === 'activeTabSetting' && !activeTabSetting.value) refresh()
      else if (data === 'activeTabComunication' && !activeTabComunication.value) refresh()
      else if (data === 'activeTabSecurity' && !activeTabSecurity.value) refresh()
      else if (data === 'activeTabSubscription' && !activeTabSubscription.value) refresh()
    }

    return {
      company,
      activeTabCompanyData,
      activeTabSetting,
      activeTabComunication,
      activeTabSecurity,
      activeTabSubscription,
      companyDataRef,
      settingRef,

      refreshData,
    }
  },
}
</script>
