<template>
  <b-tabs
    small
    justified
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab
      :active.sync="activeTabSettingGeneral"
      @click="refreshData('activeTabSettingGeneral')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        General
      </template>

      <account-setting-general
        :general-data="{}"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        Change Password
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        Orçamento
      </template>

      <setting-budget
        ref="settingBudgetRef"
        :company="company"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="MailIcon"
          size="18"
          class="mr-50"
        />
        E-mail
      </template>

      <setting-email />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        Notifications
      </template>

      <account-setting-notification
        :notification-data="{}"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'
import AccountSettingGeneral from './SettingGeneral.vue'
import AccountSettingPassword from './SettingPassword.vue'
import SettingBudget from './SettingBudget.vue'
import SettingEmail from './SettingEmail.vue'
import AccountSettingNotification from './SettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    SettingBudget,
    SettingEmail,
    AccountSettingNotification,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const settingBudgetRef = ref(null)
    const activeTabSettingGeneral = ref(true)

    const { company } = toRefs(props)
    const refresh = () => {
      settingBudgetRef.value.companyLocal = JSON.parse(JSON.stringify(company.value))
    }

    const refreshData = data => {
      if (data === 'activeTabSettingGeneral' && !activeTabSettingGeneral.value) refresh()
      // else if (data === 'activeTabSetting' && !activeTabSetting.value) refresh()
      // else if (data === 'activeTabComunication' && !activeTabComunication.value) refresh()
      // else if (data === 'activeTabSecurity' && !activeTabSecurity.value) refresh()
      // else if (data === 'activeTabSubscription' && !activeTabSubscription.value) refresh()
    }

    return {
      settingBudgetRef,
      activeTabSettingGeneral,

      refreshData,
    }
  },
}
</script>
